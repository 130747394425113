import React from 'react'
import {
  Table,
  Popover,
  Dropdown,
  Menu,
} from 'antd';
import {
  EllipsisOutlined,
} from '@ant-design/icons';
import DataList from '../UsersTable/DataList';
import { showConfirm } from '../utils/showConfirm';


const ApiKeysTable = props => {
  const { 
    api_keys,
    can_read_users,
    can_read_grantings,
  } = props

  const columns = [
    {
      title: 'Application',
      key: 'title',
      width: 250,
      fixed: 'left',
      render: (record) => (
        <>
          <div><b>{record.title}</b></div>
          {record.contact && (
            <div className="text-small">
              Contact: {record.contact}
            </div>
          )}
        </>
      )
    },
    {
      title: 'User',
      key: 'user',
      render: (record) => {
        if(!record.api_user?.id) return '-'
        return(
          <>
            <div>
              {can_read_users ? (
                <Popover content="Display user">
                  <a className="inline-block" href={`/settings/users?search=${record.api_user.fullname}`} target="_blank">
                    <b>{record.api_user.fullname}</b>
                    <div className="text-small">{record.api_user.email}</div>
                  </a>
                </Popover>
              ) : (
                <>
                  <b>{record.api_user.fullname}</b>
                  <div className="text-small">{record.api_user.email}</div>
                </>
              )}
            </div>
            <div>
              {can_read_grantings ? (
                record.api_user.grantings_count > 0
                ? (
                  <Popover content="Display grantings">
                    <a className="inline-block" href={`/settings/grantings?search=${record.api_user.fullname}`} target="_blank">
                      {record.api_user.grantings_count} granting(s)
                    </a>
                  </Popover>
                )
                : 'No grantings yet'
              ) : (
                record.api_user.grantings_count > 0
                ? `{record.api_user.grantings_count} granting(s)`
                : 'No grantings yet'
              )}
            </div>
          </>
        )
      }
    },
    {
      title: 'Authorized IP addresses',
      key: 'authorizedIpAddresses',
      dataIndex: 'authorized_ip_addresses',
    },
    {
      title: 'Last Request',
      key: 'lastRequestAt',
      render: (record) => record.last_request_at ? record.last_request_at : '-'
    },
    {
      title: 'Last request IP',
      key: 'lastRequestIP',
      render: (record) => record.last_request_ip ? record.last_request_ip : '-'
    },
    {
      title: 'Requests count',
      key: 'requestsCount',
      align: 'right',
      width: 100,
      render: (record) => record.requests_count ? record.requests_count : '-',
    },
    {
      key: 'actions',
      align: 'right',
      width: 54,
      fixed: 'right',
      render: (record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="viewApiKey">
                <a href={`/settings/api_keys/${record.id}`} data-remote="true">
                  <i className="fas fa-eye mr-5"/>
                  {I18n.t("show")}
                </a>
              </Menu.Item>
              <Menu.Item key="viewApiKeyUsage">
                <a href={`/settings/api_keys/${record.id}/usage`} data-remote="true">
                  <i className="fas fa-chart-line mr-5"/>
                  Usage
                </a>
              </Menu.Item>
              <Menu.Item key="displayApiKeyHistory">
                <a href={`/api_keys/${record.id}/versions`} data-remote="true">
                  <i className="fas fa-history mr-5"/>
                  {I18n.t("versions.display_versions")}
                </a>
              </Menu.Item>
              <Menu.Item
                key="deleteApiKey"
                disabled={!record.deletable}
              >
                <a onClick={() => showConfirm(`deleteApiKey${record.id}`)}>
                  <i className="fas fa-trash mr-5"/>
                  {I18n.t("destroy")}
                </a>
                <a style={{ display: 'none' }} href={`/settings/api_keys/${record.id}`} id={`deleteApiKey${record.id}`} data-method="delete" data-remote="true" />
              </Menu.Item>
            </Menu>
          }
        >
          <span className="ellipsis-container">
            <EllipsisOutlined rotate={90} style={{ fontSize: 15 }} />
          </span>
        </Dropdown>
      ),
    },
  ]

  return (
    <>
      <Table
        className="vertical-align-top"
        dataSource={api_keys}
        columns={columns}
        rowKey="id"
        pagination={false}
        size="middle"
        bordered
        sticky
        scroll={{ x: true }}
      />
    </>
  )
}

export default ApiKeysTable;