import { notification } from "antd";
import { isArray } from "lodash";

const showNotification = flash => {
  notification[flash.type || "open"]({
    message: flash.description || null,
    description: flash.title || null,
    placement: "bottomRight",
    duration: 4,
  });
};

const handleNotifications = notifications => {
  if (notifications && isArray(notifications)) {
    notifications.map(notification => showNotification(notification));
  }
};

export default handleNotifications;
