import React from 'react';
import {
  Table,
  Dropdown,
  Space,
  Tooltip,
  Menu,
} from 'antd';
import {
  EllipsisOutlined,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
} from '@ant-design/icons';
import { showConfirm } from '../utils/showConfirm';

const BusinessPlanCampaignsTable = (props) => {
  const {
    campaigns,
  } = props;

  const columns = [
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      render: (_text, record) => (
        <>
          <Space>
            {record.active ? (
              <Tooltip
                arrowPointAtCenter
                placement="topLeft"
                title="The campaign is enabled."
              >
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Tooltip>
            ) : (
              <Tooltip
                arrowPointAtCenter
                placement="topLeft"
                title="The campaign is disabled."
              >
                <MinusCircleTwoTone twoToneColor="#d9d9d9" />
              </Tooltip>
            )}
            {record.display_title}
          </Space>
        </>
      ),
    },
    {
      key: 'actions',
      align: 'right',
      dataIndex: 'id',
      width: 54,
      render: (id, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="editCampaign"
              >
                <a href={`/budget/business_plan_campaigns/${record.id}/edit`} data-remote="true">
                  <i className="fas fa-pen mr-5"/>
                  {I18n.t("edit")}
                </a>
              </Menu.Item>
              <Menu.Item
                key="deleteCampaign"
                disabled={!record.deletable}
              >
                <a onClick={() => showConfirm('deleteCampaignLink')}>
                  <i className="fas fa-trash mr-5"/>
                  {I18n.t("destroy")}
                </a>
                <a style={{ display: 'none' }} href={`/budget/business_plan_campaigns/${record.id}`} id="deleteCampaignLink" data-method="delete" data-remote="true" />
              </Menu.Item>
            </Menu>
          }
        >
          <span className="ellipsis-container">
            <EllipsisOutlined rotate={90} style={{ fontSize: 15 }} />
          </span>
        </Dropdown>
      ),
    },
  ];

  return (
    <Table
      dataSource={campaigns}
      columns={columns}
      rowKey="id"
      bordered
      size="middle"
      sticky
      pagination={false}
    />
  );
};

export default BusinessPlanCampaignsTable;
