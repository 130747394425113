import React, { useState, useEffect } from 'react';
import {
  Col,
  InputNumber,
  Row,
  Table,
  Space,
  Tooltip,
  Popconfirm,
} from 'antd';
import HeadcountService from '../../react/services/headcount';

const BusinessPlanPopulationsTable = (props) => {
  const {
    campaign,
    business_unit_id,
    category,
    editable,
  } = props;

  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState([]);
  const [data, setData] = useState([]);
  
  const [editingCell, setEditingCell] = useState({
    jf: null,
    year: null,
    headcount: null
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if(editingCell.jf && editingCell.year) {
      document.getElementById('hcInput').focus()
    }
  }, [editingCell])

  const fetchData = () => {
    HeadcountService.businessPlanPopulations(campaign, business_unit_id, category).then((res) => {
      setYears(res.data?.years)
      setData(res?.data.data)
      setLoading(false)
    })
  }

  const setUpdating = (cell, jfId, year, headcount) => {
    if (!editable) return;
    if (editingCell.jf == jfId && editingCell.year == year) {
    } else {
      setEditingCell({
        jf: jfId,
        year: year,
        headcount: headcount
      })  
    }
  }

  const updateData = (jf_id, year, val) => {
    // avoid update on each blur
    if (val != editingCell.headcount) {
      HeadcountService.saveBusinessPlanPopulation(campaign, business_unit_id, jf_id, year, category, val).then((res) => {
        fetchData()
        if (res.data.data.success) {
          setEditingCell({
            jf: null,
            year: null,
            headcount: null
          })
        }
      })
    } else {
      setEditingCell({
        jf: null,
        year: null,
        headcount: null
      })
    }
  }

  var columns = [
    {
      title: 'Job Family',
      key: 'job_families',
      fixed: 'left',
      ellipsis: true,
      onCell: (record) => ({
        className: record.job_family_id ? null : 'total-column'
      }),
      render: (record) => record.job_family,
    }
  ]

  const classNameForRecord = (record, year) => {
    if (!record.job_family_id) return 'total-column';
    if (!editable) return 'edit-column'
    if (editingCell.jf == record.job_family_id && editingCell.year == year) return 'edit-column editable editing';
    return 'edit-column editable';
  }

  years && years.forEach((year) => {
    columns.push({
      title: year,
      key: `header_${year}`,
      dataIndex: year,
      align: 'center',
      width: 140,
      ellipsis: true,
      onCell: (record, rowIndex) => ({
        className: classNameForRecord(record, year),
        onClick: (e) => {
          if (!record.job_family_id) return;
          if (e.target.matches('input')) return;
          setUpdating(editingCell, record.job_family_id, year, record[year] || '0.0');
        }
      }),
      render: (text, record) => {
        if (!record.job_family_id) return text || "0.0"
        if (record?.job_family_id == editingCell.jf && year == editingCell.year) {
          return (
            <InputNumber
              id="hcInput"
              precision={1}
              step={0.5}
              controls={false}
              bordered={false}
              defaultValue={text || 0.0}
              size="small"
              className="in-table-edit-input"
              onBlur={(i) => updateData(record.job_family_id, year, i.target.value)}
              onPressEnter={(i) => {
                updateData(record.job_family_id, year, i.target.value);
                i.stopPropagation();
                i.preventDefault();
              }}
            />
          )
        }
        return (
          <>
            {text || "0.0"}
          </>
        )
      },
    })
  })

  return (
    <Row>
      <Col lg={years && years.length < 4 ? 12 : 24}>
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          rowKey="id"
          bordered
          size="middle"
          sticky
          scroll={{ x: "max-content" }}
          tableLayout="fixed"
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default BusinessPlanPopulationsTable;
