import React from 'react';
import {
  Table,
  Dropdown,
  Menu,
} from 'antd';
import {
  EllipsisOutlined,
} from '@ant-design/icons';
import { showConfirm } from '../utils/showConfirm';

const BudgetTemporaryMovementsTable = (props) => {
  const {
    temporary_movements,
    display_pfa,
    editable
  } = props;

  const columns = [
    {
      title: 'Created on',
      key: 'created_at',
      dataIndex: 'created_at'
    },
    {
      title: 'Legal entity (contract)',
      key: 'contract_legal_entity',
      render: (_text, record) => record.contract_legal_entity?.title
    },
    {
      title: 'Legal entity (working place)',
      key: 'working_place_legal_entity',
      render: (_text, record) => record.working_place_legal_entity?.title
    },
    {
      title: 'BU/Region/Corp Function',
      key: 'business_unit',
      render: (_text, record) => record.business_unit?.title
    },
    {
      title: 'Job family',
      key: 'job_family',
      render: (_text, record) => record.job_family?.title
    },
    {
      title: 'Budgeted temporary headcount',
      key: 'budget',
      render: (_text, record) => record?.budget_count
    },
    {
      title: 'Comment',
      key: 'comment',
      dataIndex: 'comment',
    },
    ...(display_pfa
      ? [{
          title: 'Forecasted temporary headcount',
          key: 'pfa',
          render: (_text, record) => record.pfa_count || '-'
        }
      ]
    : []),
    ...(editable
      ? [{
          key: 'actions',
          align: 'right',
          dataIndex: 'id',
          width: 54,
          render: (id, record) => (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="editMovement"
                  >
                    <a href={`/budget/temporary_movements/${record.id}/edit`} data-remote="true">
                      <i className="fas fa-pen mr-5"/>
                      {I18n.t("edit")}
                    </a>
                  </Menu.Item>
                  <Menu.Item
                    key="deleteMovement"
                    disabled={!record.deletable}
                  >
                    <a onClick={() => showConfirm(`deleteMovementLink${record.id}`)}>
                      <i className="fas fa-trash mr-5"/>
                      {I18n.t("destroy")}
                    </a>
                    <a style={{ display: 'none' }} href={`/budget/temporary_movements/${record.id}`} id={`deleteMovementLink${record.id}`} data-method="delete" data-remote="true" />
                  </Menu.Item>
                </Menu>
              }
            >
              <span className="ellipsis-container">
                <EllipsisOutlined rotate={90} style={{ fontSize: 15 }} />
              </span>
            </Dropdown>
          ),
        },
      ]
    : []),
  ];

  return (
    <Table
      dataSource={temporary_movements}
      columns={columns}
      rowKey="id"
      bordered
      size="middle"
      sticky
      pagination={false}
    />
  );
};

export default BudgetTemporaryMovementsTable;
