import client from "../../components/utils/client";

class HeadcountService {
  static temporaries(reporting_date, contract_legal_entity_id) {
      return client.get('/budget/temporaries', { params: { reporting_date, contract_legal_entity_id } }).then((response) => {
      return response.data;
    });
  }

  static businessPlanPopulations(campaign, business_unit_id, category) {
    return client.get('/budget/business_plan_populations', { params: { campaign, business_unit_id, category } }).then((response) => {
    return response.data;
  });
}

  static saveTemporary(reporting_date, contract_legal_entity_id, business_unit_id, job_family_id, headcount) {
    var params = {
      reporting_date,
      contract_legal_entity_id,
      business_unit_id,
      job_family_id,
      headcount
    }
    return client.put('/budget/temporaries', { temporary: params, contract_legal_entity_id });
  }

  static saveBusinessPlanPopulation(campaign, business_unit_id, job_family_id, year, category, headcount) {
    var params = {
      campaign,
      business_unit_id,
      job_family_id,
      year,
      category,
      headcount
    }
    return client.put('/budget/business_plan_populations', { business_plan_population: params });
  }

  static deleteTemporaries(reporting_date, contract_legal_entity_id, business_unit_id) {
    var params = {
      reporting_date,
      contract_legal_entity_id,
      business_unit_id,
    }
    return client.delete('/budget/temporaries', { params });
  }
}

export default HeadcountService;
