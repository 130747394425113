import React, { useState, useEffect } from 'react';
import {
  Col,
  InputNumber,
  Row,
  Table,
  Space,
  Tooltip,
  Popconfirm,
} from 'antd';
import {
  CloseOutlined,
} from '@ant-design/icons';
import HeadcountService from '../../react/services/headcount';

const TemporariesTable = (props) => {
  const {
    reporting_date,
    contract_legal_entity_id,
    editable
  } = props;

  const [loading, setLoading] = useState(true);
  const [buHeaders, setBuHeaders] = useState([]);
  const [data, setData] = useState([]);
  
  const [editingCell, setEditingCell] = useState({
    jf: null,
    bu: null,
    headcount: null
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if(editingCell.jf && editingCell.bu) {
      document.getElementById('hcInput').focus()
    }
  }, [editingCell])

  const fetchData = () => {
    HeadcountService.temporaries(reporting_date, contract_legal_entity_id).then((res) => {
      setBuHeaders(res.data?.buHeaders)
      setData(res?.data.data)
      setLoading(false)
    })
  }

  const setUpdating = (cell, jfId, buIndex, headcount) => {
    if (!editable) return;
    if (editingCell.jf == jfId && editingCell.bu == buIndex) {
    } else {
      setEditingCell({
        jf: jfId,
        bu: buIndex,
        headcount: headcount
      })  
    }
  }

  const updateData = (jf_id, bu, val) => {
    var bu_id = bu.split('_')[1]
    // avoid update on each blur
    if (val != editingCell.headcount) {
      HeadcountService.saveTemporary(reporting_date, contract_legal_entity_id, bu_id, jf_id, val).then((res) => {
        fetchData()
        if (res.data.data.success) {
          setEditingCell({
            jf: null,
            bu: null,
            headcount: null
          })
        }
      })
    } else {
      setEditingCell({
        jf: null,
        bu: null,
        headcount: null
      })
    }
  }

  const deleteBusinessUnit = (bu) => {
    var bu_id = bu.split('_')[1]
    HeadcountService.deleteTemporaries(reporting_date, contract_legal_entity_id, bu_id).then((res) => {
      fetchData()
    })
  }

  var columns = [
    {
      title: 'Job Family',
      key: 'job_families',
      fixed: 'left',
      ellipsis: true,
      onCell: (record) => ({
        className: record.job_family_id ? null : 'total-column'
      }),
      render: (record) => (
        <>
          {record.job_family}
        </>
      ),
    }
  ]

  const classNameForRecord = (record, bu) => {
    if (!record.job_family_id) return 'total-column';
    if (!editable) return 'edit-column'
    if (editingCell.jf == record.job_family_id && editingCell.bu == bu) return 'edit-column editable editing';
    return 'edit-column editable';
  }

  buHeaders && buHeaders.forEach((businessUnit) => {
    columns.push({
      title: () => {
        if(!editable) return businessUnit?.title;
        return (
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', wordWrap: 'break-word', wordBreak: 'break-word' }}>
            <Tooltip title={`Click to remvoe temporary values from for ${businessUnit.title}`}>
              <Popconfirm
                title={`This will remove all temporary values for ${businessUnit.title}. Are you sure ?`}
                onConfirm={() => deleteBusinessUnit(businessUnit.index)}
                okText="Yes"
              >
                <CloseOutlined style={{ marginRight: '5px'}} />
              </Popconfirm>
            </Tooltip>
            {businessUnit?.title}
          </div>
        )
      },
      key: `header_${businessUnit?.title}`,
      dataIndex: businessUnit?.index,
      align: 'center',
      width: 140,
      ellipsis: true,
      onCell: (record, rowIndex) => ({
        className: classNameForRecord(record, businessUnit.index),
        onClick: (e) => {
          if (!record.job_family_id) return;
          if (e.target.matches('input')) return;
          setUpdating(editingCell, record.job_family_id, businessUnit.index, record[businessUnit.index] || '0.0');
        }
      }),
      render: (text, record) => {
        if (!record.job_family_id) return text || "0.0"
        if (record?.job_family_id == editingCell.jf && businessUnit?.index == editingCell.bu) {
          return (
            <InputNumber
              id="hcInput"
              precision={1}
              step={0.5}
              controls={false}
              bordered={false}
              defaultValue={text || 0.0}
              size="small"
              className="in-table-edit-input"
              onBlur={(i) => updateData(record.job_family_id, businessUnit.index, i.target.value)}
              onPressEnter={(i) => {
                updateData(record.job_family_id, businessUnit.index, i.target.value);
                i.stopPropagation();
                i.preventDefault();
              }}
            />
          )
        }
        return (
          <>
            {text || "0.0"}
          </>
        )
      },
    })
  })

  columns.push({
    title: 'Total',
    key: 'jf_total',
    dataIndex: 'total',
    align: 'center',
    width: 140,
    ellipsis: true,
    onCell: (record) => ({
      className: 'total-column'
    }),
    render: (text) => (
      <>
        {text || "0.0"}
      </>
    )
  })

  return (
    <Row>
      <Col lg={buHeaders && buHeaders.length < 4 ? 12 : 24}>
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          rowKey="id"
          bordered
          size="middle"
          sticky
          scroll={{ x: "max-content" }}
          tableLayout="fixed"
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default TemporariesTable;
