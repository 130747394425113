import React from 'react';
import { Modal } from 'antd';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';

export const showConfirm = (linkId, title=null) => {
  var title = title || 'Are you sure ?'
  Modal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    onOk() {
      document.getElementById(linkId).click();
    }
  });
}