import React from 'react'
import {
  Table,
  Popover,
  Dropdown,
  Menu,
} from 'antd';
import {
  EllipsisOutlined,
} from '@ant-design/icons';
import DataList from '../UsersTable/DataList';
import { showConfirm } from '../utils/showConfirm';


const PermissionGroupsTable = props => {
  const { 
    permission_groups,
    can_display_versions,
  } = props

  const columns = [
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      width: 200,
      fixed: 'left'
    },
    {
      title: 'Permissions',
      key: 'permissions',
      ellipsis: true,
      render: (record) => {
        if (record.permissions.length > 6) {
          return (
            <Popover content={<DataList list={record.permissions}/>} mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div className="ui basic label">
                {record.permissions.length} permissions
              </div>
            </Popover>
          )
        } else if (record.permissions.length > 0) {
          return (
            record.permissions.map(permission => (
              <div className="ui basic label">
                {permission.title}
              </div>
            ))
          )
        } else {
          return '-'
        }
      }
    },
    {
      key: 'actions',
      align: 'right',
      width: 54,
      fixed: 'right',
      render: (record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="editPermissionGroup">
                <a href={`/settings/permission_groups/${record.id}/edit`} data-remote="true">
                  <i className="fas fa-pen mr-5"/>
                  {I18n.t("edit")}
                </a>
              </Menu.Item>
              {can_display_versions &&
                <Menu.Item key="displayPermissionGroupHistory">
                  <a href={`/permission_groups/${record.id}/versions`} data-remote="true">
                    <i className="fas fa-history mr-5"/>
                    {I18n.t("versions.display_versions")}
                  </a>
                </Menu.Item>
              }
              <Menu.Item
                key="deletePermissionGroup"
                disabled={!record.deletable}
              >
                <a onClick={() => showConfirm(`deletePermissionGroup${record.id}`)}>
                  <i className="fas fa-trash mr-5"/>
                  {I18n.t("destroy")}
                </a>
                <a style={{ display: 'none' }} href={`/settings/permission_groups/${record.id}`} id={`deletePermissionGroup${record.id}`} data-method="delete" data-remote="true" />
              </Menu.Item>
            </Menu>
          }
        >
          <span className="ellipsis-container">
            <EllipsisOutlined rotate={90} style={{ fontSize: 15 }} />
          </span>
        </Dropdown>
      ),
    },
  ]

  return (
    <>
      <Table
        className="vertical-align-top"
        dataSource={permission_groups}
        columns={columns}
        rowKey="id"
        pagination={false}
        size="middle"
        bordered
        sticky
        scroll={{ x: true }}
      />
    </>
  )
}

export default PermissionGroupsTable;