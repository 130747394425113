import React from 'react'

const DataList = props => {
  const {
    list
  } = props;
  return (
    <>
      {list.map(elt => (
        <div>
          • {elt?.title ? elt.title : elt}
        </div>
      ))}
    </>
  )
}

export default DataList;