import React, { useState } from 'react'
import {
  Table,
  Popover,
  Space,
  Dropdown,
  Menu,
} from 'antd';
import {
  EllipsisOutlined,
  RightSquareOutlined,
} from '@ant-design/icons';
import parse from 'html-react-parser';
import GrantingDisplay from './GrantingDisplay';
import DataList from './DataList';


const UsersTable = props => {
  const { 
    users,
    can_display_versions,
    can_read_grantings
  } = props

  const [showMoreRolesIds, setShowMoreRolesIds] = useState([])
  const [showMoreGrantingsIds, setShowMoreGrantingsIds] = useState([])

  const showMoreRoles = (recordId) => {
    if(!showMoreRolesIds.includes(recordId)) {
      setShowMoreRolesIds([...showMoreRolesIds, recordId])
    }
  }

  const showMoreGrantings = (recordId) => {
    if(!showMoreGrantingsIds.includes(recordId)) {
      setShowMoreGrantingsIds([...showMoreGrantingsIds, recordId])
    }
  }

  const columns = [
    {
      title: 'User',
      key: 'user',
      fixed: 'left',
      width: 250,
      render: (record) => (
        <>
          <div className="flex align-items-flex-start">
            <b>{record.fullname}</b>
            {record.record_type === 'api' &&
              <Popover placement="right" content="This user is used to give access in his perimeter defined by grantings to an api key.">
                <div className="status-label ml-10">
                  <b>API</b>
                </div>
              </Popover>
            }
          </div>
          <div className="text-small">{record.email}</div>
          {record.employee_id &&
            <div className="text-small">
              <a href={`/employees/${record.employee_id}`} className="underline">View employee in CORE</a>
            </div>
          }
        </>
      )
    },
    {
      title: 'Roles',
      key: 'roles',
      ellipsis: true,
      render: (record) => (
        <>
          {record.roles.length > 0 ? (
            <Space size={[8,8]} wrap>
              {record.roles.slice(0,3).map((role) => (
                <Popover content={parse(I18n.t(`activerecord.attributes.user.roles.${role}.description`))} mouseEnterDelay={0} mouseLeaveDelay={0}>
                  <div className="ui basic label">
                    {I18n.t(`activerecord.attributes.user.roles.${role}.title`)}
                  </div>
                </Popover>              
              ))}
              {record.roles.slice(3).length > 0 && (
                showMoreRolesIds.includes(record.id) ? (
                  record.roles.slice(3).map((role) => (
                    <Popover content={parse(I18n.t(`activerecord.attributes.user.roles.${role}.description`))} mouseEnterDelay={0} mouseLeaveDelay={0}>
                      <div className="ui basic label">
                        {I18n.t(`activerecord.attributes.user.roles.${role}.title`)}
                      </div>
                    </Popover>              
                  ))
                ) : (
                  <Popover content="Click to display" mouseEnterDelay={0} mouseLeaveDelay={0}>
                    <a onClick={() => showMoreRoles(record.id)}>
                      +{record.roles.slice(3).length} other
                    </a>
                  </Popover>
                )
              )}
            </Space>
          )
          : '-' }
        </>
      )
    },
    {
      title: 'Grantings',
      key: 'grantings',
      ellipsis: true,
      render: (record) => (
        <>
          {record.grantings.length > 0 ? (
            <Space size={[8,8]} wrap>
              {record.grantings.slice(0,3).map((granting) => (
                can_read_grantings ? (
                  <Popover content={<GrantingDisplay data={granting}/>} mouseEnterDelay={0} mouseLeaveDelay={0}>
                    <a href={`/settings/grantings?search=${record.email || record.title}`} className="ui basic label">
                      {granting.title}
                    </a>
                  </Popover>
                  ) : (
                  <Popover content={<GrantingDisplay data={granting}/>} mouseEnterDelay={0} mouseLeaveDelay={0}>
                    <div className="ui basic label">
                      {granting.title}
                    </div>
                  </Popover>
                )
              ))}
              {record.grantings.slice(3).length > 0 && (
                showMoreGrantingsIds.includes(record.id) ? (
                  record.grantings.slice(3).map((granting) => (
                    <Popover content={<GrantingDisplay data={granting}/>} mouseEnterDelay={0} mouseLeaveDelay={0}>
                      <div className="ui basic label">
                        {granting.title}
                      </div>
                    </Popover>
                  ))
                ) : (
                  <Popover content="Click to display" mouseEnterDelay={0} mouseLeaveDelay={0}>
                    <a onClick={() => showMoreGrantings(record.id)}>
                      +{record.grantings.slice(3).length} other
                    </a>
                  </Popover>
                )
              )}
            </Space>
          )
          : '-' }
        </>
      )

    },
    {
      title: 'Data Steward of',
      key: 'legal_entities_as_data_steward',
      dataIndex: 'legal_entities_as_core_data_steward',
      ellipsis: true,
      render: (legal_entities_as_core_data_steward) => {
        if (legal_entities_as_core_data_steward.length > 1) {
          return (
            <Popover content={<DataList list={legal_entities_as_core_data_steward}/>} mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div className="ui basic label">
                {legal_entities_as_core_data_steward.length} legal entities
              </div>
            </Popover>
          )
        } else if (legal_entities_as_core_data_steward.length == 1) {
          return (
            legal_entities_as_core_data_steward.map(legalEntity => (
              <div className="ui basic label">
                {legalEntity.title}
              </div>
            ))
          )
        } else {
          return '-'
        }
      }
    },
    {
      title: 'Payroll admin of',
      key: 'legal_entities_as_payroll_admin',
      dataIndex: 'legal_entities_as_payroll_admin',
      ellipsis: true,
      render: (legal_entities_as_payroll_admin) => {
        if (legal_entities_as_payroll_admin.length > 1) {
          return (
            <Popover content={<DataList list={legal_entities_as_payroll_admin}/>} mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div className="ui basic label">
                {legal_entities_as_payroll_admin.length} legal entities
              </div>
            </Popover>
          )
        } else if (legal_entities_as_payroll_admin.length == 1) {
          return (
            legal_entities_as_payroll_admin.map(legalEntity => (
              <div className="ui basic label">
                {legalEntity.title}
              </div>
            ))
          )
        } else {
          return '-'
        }
      }
    },
    {
      title: 'Budget validator of',
      key: 'business_units_as_budget_validator',
      dataIndex: 'business_units_as_budget_validator',
      ellipsis: true,
      render: (business_units_as_budget_validator) => {
        if (business_units_as_budget_validator.length > 1) {
          return (
            <Popover content={<DataList list={business_units_as_budget_validator}/>} mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div className="ui basic label">
                {business_units_as_budget_validator.length} Region/BU/Corp functions
              </div>
            </Popover>
          )
        } else if (business_units_as_budget_validator.length == 1) {
          return (
            business_units_as_budget_validator.map(legalEntity => (
              <div className="ui basic label">
                {legalEntity.title}
              </div>
            ))
          )
        } else {
          return '-'
        }
      }
    },
    {
      title: 'Access',
      key: 'access',
      dataIndex: 'disallowed',
      width: 125,
      align: 'center',
      render: (disallowed) => (
        <div className={`ui ${disallowed ? 'negative' : 'positive'} label`}>
          <i className={`fas ${disallowed ? 'fa-ban' : 'fa-check-circle'} mr-5`} />
          <span>{disallowed ? 'Blocked' : 'Allowed'}</span>
        </div>
      )
    },
    {
      title: 'Last Request',
      key: 'last_request_at',
      width: 125,
      render: (record) => record.last_request_at ? record.last_request_at : '-',
    },
    {
      key: 'actions',
      align: 'right',
      width: 54,
      fixed: 'right',
      render: (record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="editUser">
                <a href={`/settings/users/${record.id}/edit`} data-remote="true">
                  <i className="fas fa-pen mr-5"/>
                  {I18n.t("edit")}
                </a>
              </Menu.Item>
              {record.disallowed? (
                <Menu.Item key="allowUser">
                  <a href={`/settings/users/${record.id}/allow`} data-method="put" data-remote="true">
                    <i className="fas fa-unlock mr-5"/>
                    {I18n.t("allow")}
                  </a>
                </Menu.Item>
              ) : (
                <Menu.Item key="disallowUser">
                  <a href={`/settings/users/${record.id}/disallow`} data-method="put" data-remote="true">
                    <i className="fas fa-ban mr-5"/>
                    {I18n.t("disallow")}
                  </a>
                </Menu.Item>
              )}
              {can_display_versions &&
                <Menu.Item key="displayUserHistory">
                  <a href={`/users/${record.id}/versions`} data-remote="true">
                    <i className="fas fa-history mr-5"/>
                    {I18n.t("versions.display_versions")}
                  </a>
                </Menu.Item>
              }
              {can_read_grantings &&
                <Menu.Item key="displayUserGrantings">
                  <a href={`/settings/grantings?search=${record.email}`}>
                    <i className="fas fa-th-list mr-5"/>
                    Display grantings
                  </a>
                </Menu.Item>
              }
            </Menu>
          }
        >
          <span className="ellipsis-container">
            <EllipsisOutlined rotate={90} style={{ fontSize: 15 }} />
          </span>
        </Dropdown>
      ),
    },
  ]

  return (
    <>
      <Table
        className="vertical-align-top"
        dataSource={users}
        columns={columns}
        rowKey="id"
        pagination={false}
        size="middle"
        bordered
        sticky
        scroll={{ x: 2000 }}
      />
    </>
  )
}

export default UsersTable;