import axios from "axios";
import qs from 'qs';
import { get } from "lodash";
import { decamelizeKeys } from "humps";

import { Authenticity } from "./authenticity";
import handleNotifications from "./notifications";

const defaultHeader = {
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
  Accept: "application/json",
};

const client = axios.create({
  withCredentials: true,
});

client.interceptors.request.use(
  config => {
    const data = config.data && config.data instanceof FormData
      ? config.data
      : decamelizeKeys(config.data || {});
    if (config.method === 'get') {
      config.paramsSerializer = params => {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        });
      };
    }
    return {
      ...config,
      data,
      params: config.params ? decamelizeKeys(config.params) : null,
      headers: {
        ...config.headers,
        ...Authenticity.headers(),
        ...(config.httpRequest ? {} : defaultHeader),
      },
    };
  },
  error => Promise.reject(error),
);

client.interceptors.response.use(
  response => {
    if (response.data) {
      const notifications = get(response.data, "data.notifications", null);
      if (notifications) handleNotifications(notifications);
      return response;
    }
    return Promise.reject(response);
  },
  error => {
    const { response } = error;
    if (response) {
      if (response.data) {
        const notifications = get(response.data, "data.notifications", null);
        if (notifications) handleNotifications(notifications);
      }
    }
    return Promise.reject(error);
  },
);

export const cachedQueryCreator = () => {
  let token;
  const resources = {};
  return query => {
    if (resources[query]) {
      return new Promise(resolve => {
        resolve(resources[query]);
      });
    }
    if (token) token.cancel();
    token = axios.CancelToken.source();
    return client
      .request({
        url: query,
        cancelToken: token.token,
      })
      .then(response => {
        resources[query] = response;
        return response;
      })
      .catch(error => {});
  };
};

export default client;
