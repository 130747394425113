import React from 'react';
import {
  Table,
  Dropdown,
  Space,
  Menu,
} from 'antd';
import {
  EllipsisOutlined,
} from '@ant-design/icons';
import { showConfirm } from '../utils/showConfirm';

const BudgetMovementsTable = (props) => {
  const {
    movements,
    display_decisions
  } = props;

  const columns = [
    {
      title: 'Created on',
      key: 'created_at',
      dataIndex: 'created_at',
    },
    {
      title: 'Legal entity (contract)',
      key: 'contract_legal_entity',
      render: (_text, record) => record.contract_legal_entity?.title
    },
    {
      title: 'Legal entity (working place)',
      key: 'working_place_legal_entity',
      render: (_text, record) => record.working_place_legal_entity?.title
    },
    {
      title: 'BU/Region/Corp Function',
      key: 'business_unit',
      render: (_text, record) => record.business_unit?.title
    },
    {
      title: 'Job family',
      key: 'job_family',
      render: (_text, record) => record.job_family?.title
    },
    {
      title: 'Job title',
      key: 'job_title',
      dataIndex: 'job_title',
    },
    {
      title: 'Contract type',
      key: 'contract_type',
      render: (_text, record) => (
        <>
          <div>
            {record?.contract_type?.title}
          </div>
          <div className="text-muted">
            {record?.contract_type?.headcount_type}
          </div>
        </>
      )
    },
    {
      title: 'On',
      key: 'arrival_date',
      render: (_text, record) => (
        <div>
          { record.arrival_on }
          {record.departure_on &&
            <div>
              Departure: { record.departure_on }
            </div>
          }
        </div>
      )
    },
    {
      title: 'Assigned To',
      key: 'assigned_to',
      render: (_text, record) => record?.assigned_employee?.title || "-"
    },
    {
      title: 'Comment',
      key: 'comment',
      dataIndex: 'comment',
    },
    ...(display_decisions
      ? [
          {
          title: 'Decision',
          key: 'decision',
          render: (_text, record) => {
            if(!record.editable_decision) return record.display_decision
            return (
              <div>
                <Dropdown
                  overlay={
                    <Menu>
                      {record.decision !== null &&
                        <Menu.Item
                          key="nullifyDecisionMovement"
                        >
                          <a onClick={() => showConfirm(`nullifyDecisionMovementLink${record.id}`, 'This movement will be marked as "Pending". Are you sure ?')}>
                            {I18n.t("headcounts.movements.decisions.nullify_decision")}
                          </a>
                          <a style={{ display: 'none' }} href={`/budget/movements/${record.id}/update_decision`} id={`nullifyDecisionMovementLink${record.id}`} data-method="put" data-remote="true" />
                        </Menu.Item>
                      }
                      {record.decision !== 'discarded' &&
                        <Menu.Item
                          key="discardMovement"
                        >
                          <a onClick={() => showConfirm(`discardMovementLink${record.id}`, 'This movement will be marked as "Discarded". Are you sure ?')}>
                            {I18n.t("headcounts.movements.decisions.discard")}
                          </a>
                          <a style={{ display: 'none' }} href={`/budget/movements/${record.id}/update_decision?decision=discarded`} id={`discardMovementLink${record.id}`} data-method="put" data-remote="true" />
                        </Menu.Item>
                      }
                      {record.decision !== 'assigned' &&
                        <Menu.Item
                          key="assignMovement"
                        >
                          <a href={`/budget/movements/${record.id}/assign`} data-remote="true">
                            {I18n.t("headcounts.movements.decisions.assign")}
                          </a>
                        </Menu.Item>
                      }
                      {record.decision !== 'reported' &&
                        <Menu.Item
                          key="reportMovement"
                        >
                          <a href={`/budget/movements/${record.id}/report`} data-remote="true">
                            {I18n.t("headcounts.movements.decisions.report")}
                          </a>
                        </Menu.Item>
                      }
                      {record.decision !== 'confirmed' &&
                        <Menu.Item
                          key="confirmMovement"
                        >
                          <a href={`/budget/movements/${record.id}/confirm`} data-remote="true">
                            {I18n.t("headcounts.movements.decisions.confirm") }
                          </a>
                        </Menu.Item>
                      }
                    </Menu>
                  }
                >
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <Space>
                      {record.display_decision}
                      <i className="fas fa-caret-down"/>
                    </Space>
                  </a>
                </Dropdown>
              </div>
            )
          }          
        }
      ]
    : []),
    {
      key: 'actions',
      align: 'right',
      dataIndex: 'id',
      width: 54,
      render: (id, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="editMovement"
              >
                <a href={`/budget/movements/${record.id}/edit`} data-remote="true">
                  <i className="fas fa-pen mr-5"/>
                  {I18n.t("edit")}
                </a>
              </Menu.Item>
              <Menu.Item
                key="deleteMovement"
                disabled={!record.deletable}
              >
                <a onClick={() => showConfirm(`deleteMovementLink${record.id}`)}>
                  <i className="fas fa-trash mr-5"/>
                  {I18n.t("destroy")}
                </a>
                <a style={{ display: 'none' }} href={`/budget/movements/${record.id}`} id={`deleteMovementLink${record.id}`} data-method="delete" data-remote="true" />
              </Menu.Item>
            </Menu>
          }
        >
          <span className="ellipsis-container">
            <EllipsisOutlined rotate={90} style={{ fontSize: 15 }} />
          </span>
        </Dropdown>
      ),
    },
  ];

  return (
    <Table
      dataSource={movements}
      columns={columns}
      rowKey="id"
      bordered
      size="middle"
      sticky
      pagination={false}
    />
  );
};

export default BudgetMovementsTable;
