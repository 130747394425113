import React, { useMemo } from 'react'

import { VList } from 'virtuallist-antd'
import { Table } from 'antd'

const VirtualListAntd = (props) => {

  var windowInnerHeight = window.innerHeight - 100;

	const vComponents = useMemo(() => {
		return VList({
			height: windowInnerHeight,
		})
	}, [])

	return (
		<Table
      {...props}
			rowKey="id"
			scroll={{
        x: "max-content",
				y: windowInnerHeight
			}}
			components={vComponents}
		/>
	)
}

export default VirtualListAntd;